import React, { Fragment } from 'react';
import providerService from 'root/utils/providerService';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { gu_getSettingFromPortalSettings } from '../../../../utils/general-util';

const controller = {
    vm: null,
    // to map each provider's bg image and animation
    // suspend = initial position before animation
    // after animation need to change at casino.scss there
    // .casino-panel > .slider > .slider-container > div > container > .<providerCode>
    providerData: {
        AG2: {
            key: 'ag',
            provider: 'AG2',
            suspend: [
                { left: 680, bottom: 170, width: 77, height: 83, suspendImg: '/public/html/images/casino/icon/suspend/1_2.png' },
                { left: 500, bottom: 270, width: 113, height: 119, suspendImg: '/public/html/images/casino/icon/suspend/1_3.png' },
            ],
            text: 'casino:casino.ag.desc_1',
            bgImg: '/public/html/images/casino/1.png',
            routeName: 'asia-gaming-2',
            maintenance: false,
        },
        SA: {
            key: 'sa_gaming',
            provider: 'SA',
            suspend: [
                { left: 490, bottom: 220, width: 115, height: 119, suspendImg: '/public/html/images/casino/icon/suspend/2_2.png' },
                { left: 620, bottom: 330, width: 71, height: 68, suspendImg: '/public/html/images/casino/icon/suspend/2_3.png' },
            ],
            text: 'casino:casino.sa.desc_1',
            bgImg: '/public/html/images/casino/2.png',
            routeName: 'sa',
            maintenance: false,
        },
        DG: {
            key: 'dream_gaming',
            provider: 'DG',
            suspend: [{ left: 360, bottom: -20, width: 368, height: 339, suspendImg: '/public/html/images/casino/icon/suspend/3_2.png' }],
            text: 'casino:casino.dg.desc_1',
            bgImg: '/public/html/images/casino/3.png',
            blockImg: '/public/html/images/casino/icon/suspend/3_3.png',
            girlImg: '/public/html/images/casino/icon/suspend/3_4.png',
            routeName: 'dream-gaming',
            maintenance: false,
            style: { height: '6rem' },
        },
        UG: {
            key: 'ae_sexy',
            provider: 'UG',
            suspend: [
                { left: 560, bottom: 360, width: 118, height: 51, suspendImg: '/public/html/images/casino/icon/suspend/4_2.png' },
                { left: 420, bottom: 310, width: 193, height: 60, suspendImg: '/public/html/images/casino/icon/suspend/4_3.png' },
                { left: 580, bottom: 280, width: 181, height: 84, suspendImg: '/public/html/images/casino/icon/suspend/4_4.png' },
            ],
            text: 'casino:casino.ug.desc_1',
            bgImg: '/public/html/images/casino/4.png',
            routeName: 'sexy-baccarat',
            maintenance: false,
        },
        AES: {
            key: 'ae_sexy',
            provider: 'AES',
            suspend: [
                { left: 560, bottom: 360, width: 118, height: 51, suspendImg: '/public/html/images/casino/icon/suspend/4_2.png' },
                { left: 420, bottom: 310, width: 193, height: 60, suspendImg: '/public/html/images/casino/icon/suspend/4_3.png' },
                { left: 580, bottom: 280, width: 181, height: 84, suspendImg: '/public/html/images/casino/icon/suspend/4_4.png' },
            ],
            text: 'casino:casino.ug.desc_1',
            bgImg: '/public/html/images/casino/4.png',
            routeName: 'sexy-baccarat',
        },
        AES2: {
            key: 'ae_sexy',
            provider: 'AES2',
            suspend: [
                { left: 560, bottom: 360, width: 118, height: 51, suspendImg: '/public/html/images/casino/icon/suspend/4_2.png' },
                { left: 420, bottom: 310, width: 193, height: 60, suspendImg: '/public/html/images/casino/icon/suspend/4_3.png' },
                { left: 580, bottom: 280, width: 181, height: 84, suspendImg: '/public/html/images/casino/icon/suspend/4_4.png' },
            ],
            text: 'casino:casino.ug.desc_1',
            bgImg: '/public/html/images/casino/4.png',
            routeName: 'sexy-baccarat2',
        },
        EBET: {
            key: 'ebet',
            provider: 'EBET',
            suspend: [
                { left: 710, bottom: 0, width: 147, height: 163, suspendImg: '/public/html/images/casino/icon/suspend/5_2.png' },
                { left: 660, bottom: 230, width: 117, height: 123, suspendImg: '/public/html/images/casino/icon/suspend/5_3.png' },
            ],
            text: 'casino:casino.ebet.desc_1',
            bgImg: '/public/html/images/casino/5.png',
            routeName: 'ebet',
            maintenance: false,
        },
        AB: {
            key: 'allbet',
            provider: 'AB',
            suspend: [
                { left: 480, bottom: 200, width: 112, height: 123, suspendImg: '/public/html/images/casino/icon/suspend/6_2.png' },
                { left: 40, bottom: 320, width: 152, height: 159, suspendImg: '/public/html/images/casino/icon/suspend/6_3.png' },
            ],
            text: 'casino:casino.ab.desc_1',
            bgImg: '/public/html/images/casino/6.png',
            routeName: 'allbet',
            maintenance: false,
        },
        AB2: {
            key: 'allbet',
            provider: 'AB2',
            suspend: [
                { left: 480, bottom: 200, width: 112, height: 123, suspendImg: '/public/html/images/casino/icon/suspend/6_2.png' },
                { left: 40, bottom: 320, width: 152, height: 159, suspendImg: '/public/html/images/casino/icon/suspend/6_3.png' },
            ],
            text: 'casino:casino.ab.desc_1',
            bgImg: '/public/html/images/casino/6.png',
            routeName: 'allbet',
            maintenance: false,
        },
        EVO: {
            key: 'evolution',
            provider: 'EVO',
            suspend: [
                { left: 440, bottom: 200, width: 65, height: 71, suspendImg: '/public/html/images/casino/icon/suspend/7_2.png' },
                { left: 550, bottom: 310, width: 110, height: 109, suspendImg: '/public/html/images/casino/icon/suspend/7_3.png' },
            ],
            text: 'casino:casino.eg.desc_1',
            bgImg: '/public/html/images/casino/7.png',
            routeName: 'evolution-gaming3',
            maintenance: false,
        },
        MGP: {
            key: 'mg',
            provider: 'MGP',
            suspend: [
                { left: 80, bottom: 350, width: 118, height: 115, suspendImg: '/public/html/images/casino/icon/suspend/8_2.png' },
                { left: 610, bottom: 245, width: 75, height: 74, suspendImg: '/public/html/images/casino/icon/suspend/8_3.png' },
            ],
            text: 'casino:casino.mgp.desc_1',
            bgImg: '/public/html/images/casino/8.png',
            routeName: 'microgamingPlus',
            maintenance: false,
        },
        GP: {
            key: 'gameplay',
            provider: 'GP',
            suspend: [
                { left: 560, bottom: 200, width: 63, height: 65, suspendImg: '/public/html/images/casino/icon/suspend/9_2.png' },
                { left: 130, bottom: 310, width: 101, height: 104, suspendImg: '/public/html/images/casino/icon/suspend/9_3.png' },
            ],
            text: 'casino:casino.gp.desc_1',
            bgImg: '/public/html/images/casino/9.png',
            routeName: 'game-play',
            maintenance: false,
        },
        PT2: {
            key: 'playtech',
            provider: 'PT2',
            suspend: [
                { left: 610, bottom: 220, width: 77, height: 70, suspendImg: '/public/html/images/casino/icon/suspend/10_2.png' },
                { left: 460, bottom: 320, width: 104, height: 93, suspendImg: '/public/html/images/casino/icon/suspend/10_3.png' },
            ],
            text: 'casino:casino.pt.desc_1',
            bgImg: '/public/html/images/casino/10.png',
            routeName: 'playtech2',
            maintenance: false,
        },
        PTL: {
            key: 'playtech',
            provider: 'PTL',
            suspend: [
                { left: 610, bottom: 220, width: 77, height: 70, suspendImg: '/public/html/images/casino/icon/suspend/10_2.png' },
                { left: 460, bottom: 320, width: 104, height: 93, suspendImg: '/public/html/images/casino/icon/suspend/10_3.png' },
            ],
            text: 'casino:casino.pt.desc_1',
            bgImg: '/public/html/images/casino/10.png',
            routeName: 'playtech3',
            maintenance: false,
        },
        GD: {
            key: 'gd',
            provider: 'GD',
            suspend: [
                { left: 590, bottom: 350, width: 59, height: 52, suspendImg: '/public/html/images/casino/icon/suspend/11_2.png' },
                { left: 460, bottom: 280, width: 126, height: 83, suspendImg: '/public/html/images/casino/icon/suspend/11_3.png' },
            ],
            text: 'casino:casino.gd.desc_1',
            bgImg: '/public/html/images/casino/11.png',
            routeName: 'gd-gaming',
            maintenance: false,
        },
        WM: {
            key: 'wm',
            provider: 'WM',
            suspend: [
                { left: 560, bottom: 160, width: 130, height: 130, suspendImg: '/public/html/images/casino/icon/suspend/12_2.png' },
                { left: 120, bottom: 330, width: 110, height: 100, suspendImg: '/public/html/images/casino/icon/suspend/12_3.png' },
            ],
            text: 'casino:casino.wm.desc_1',
            bgImg: '/public/html/images/casino/12.png',
            routeName: 'wm-casino',
            maintenance: false,
        },
        W: {
            key: 'w',
            provider: 'W',
            suspend: [
                { left: 560, bottom: 160, width: 130, height: 130, suspendImg: '/public/html/images/casino/icon/suspend/8_2.png' },
                { left: 120, bottom: 330, width: 110, height: 100, suspendImg: '/public/html/images/casino/icon/suspend/9_3.png' },
            ],
            routeName: 'w-casino',
            text: 'casino:casino.w.desc_1',
            bgImg: '/public/html/images/casino/w.png',
            maintenance: false,
        },
        PPL: {
            key: 'ppl',
            provider: 'PPL',
            suspend: [
                { left: 80, bottom: 200, width: 90, height: 86, suspendImg: '/public/html/images/casino/icon/suspend/13_2.png' },
                { left: 510, bottom: 300, width: 110, height: 105, suspendImg: '/public/html/images/casino/icon/suspend/13_3.png' },
            ],
            text: 'casino:casino.ppl.desc_1',
            bgImg: '/public/html/images/casino/13.png',
            routeName: 'pragmaticplay',
            maintenance: false,
        },
        BG: {
            key: 'biggaming',
            provider: 'BG',
            suspend: [
                { left: 560, bottom: 160, width: 130, height: 130, suspendImg: '/public/html/images/casino/icon/suspend/14_2.png' },
                { left: 120, bottom: 330, width: 110, height: 100, suspendImg: '/public/html/images/casino/icon/suspend/14_3.png' },
            ],
            text: 'casino:casino.bg.desc_1',
            bgImg: '/public/html/images/casino/14.png',
            routeName: 'biggaming',
            maintenance: false,
        },
        BINL2: {
            key: 'bbin2',
            provider: 'BINL2',
            suspend: [
                { left: 460, bottom: 320, width: 104, height: 93, suspendImg: '/public/html/images/casino/icon/suspend/15_2.png' },
                { left: 610, bottom: 245, width: 75, height: 74, suspendImg: '/public/html/images/casino/icon/suspend/15_3.png' },
                { left: 610, bottom: 245, width: 75, height: 74, suspendImg: '/public/html/images/casino/icon/suspend/15_4.png' },
            ],
            text: 'casino:casino.binl2.desc_1',
            bgImg: '/public/html/images/casino/15.png',
            routeName: 'bbin',
            maintenance: false,
        },
        WE: {
            key: 'we',
            provider: 'WE',
            suspend: [
                { left: 560, bottom: 160, width: 130, height: 130, suspendImg: '/public/html/images/casino/icon/suspend/14_2.png' },
                { left: 120, bottom: 330, width: 110, height: 100, suspendImg: '/public/html/images/casino/icon/suspend/6_3.png' },
            ],
            routeName: 'we-casino',
            text: 'casino:casino.we.desc_1',
            bgImg: '/public/html/images/casino/we.png',
            maintenance: false,
        },
        YBL: {
            key: 'ybl',
            provider: 'YBL',
            suspend: [
                { left: 560, bottom: 110, width: 130, height: 140, suspendImg: '/public/html/images/casino/icon/suspend/16_1.png' },
                { left: 400, bottom: 100, width: 100, height: 65, suspendImg: '/public/html/images/casino/icon/suspend/16_2.png' },
            ],
            routeName: 'ybl',
            text: 'casino:casino.ybl.desc_1',
            bgImg: '/public/html/images/casino/ybl.png',
            maintenance: false,
        },
        VC: {
            key: 'vc',
            provider: 'VC',
            suspend: [
                { left: 610, bottom: 220, width: 77, height: 70, suspendImg: '/public/html/images/casino/icon/suspend/10_2.png' },
                { left: 130, bottom: 310, width: 101, height: 104, suspendImg: '/public/html/images/casino/icon/suspend/9_3.png' },
            ],
            routeName: 'vc',
            text: 'casino:casino.vc.desc_1',
            bgImg: '/public/html/images/casino/vc.png',
            maintenance: false,
        },
    },

    init(vm) {
        this.vm = vm;
        this.vm.state = {
            list: [],
            providerCodeList: [],
            isInit: true,
            selectedIndex: null,
            compSettings: [],
        };
    },

    initCompSettings() {
        return new Promise((resolve) => {
            // Step 1: Standard way to get from portal settings
            let enableCNYTheme = gu_getSettingFromPortalSettings({
                vm: controller.vm,
                settingName: 'enableCNYTheme',
            });

            // Step 2: Massage again to get the specific data you need (Skip if not needed)
            // Step 3: Initialize all into compSettings
            let compSettings = {
                enableCNYTheme: enableCNYTheme,
            };

            // Use setState callback to ensure the state has been updated before logging
            controller.vm.setState({ compSettings: compSettings }, () => {
                resolve();
            });
        });
    },

    getCasinoProviderList(isMobile = false) {
        const { language, t } = controller.vm.props;
        let contentType = 'web';
        let currencyLang = language.currencyLang;

        if (language.country === 'KH') currencyLang = 'KHUSD';

        if (isMobile) {
            contentType = 'mobile';
        }
        return new Promise(function (resolve) {
            let loadGetSubmenu = new Promise((resolve) => {
                window.SPL_Content.getSubmenu(language.countryLanguageKey, contentType, 'C', false).then((data) => resolve(data));
            });

            let loadProviderGameMaintenanceList = new Promise((resolve) => {
                window.SPL_Provider.providerGameMaintenanceList(null, null, currencyLang).then((result) => resolve(result));
            });

            Promise.all([loadGetSubmenu, loadProviderGameMaintenanceList]).then((loadResult) => {
                let submenu = loadResult[0];
                let providerMaintenance = loadResult[1];

                let count = 0;
                let list = [];
                let casinoData = []; // reset value first
                let providerCodeList = [];
                casinoData.length = 0;

                for (let i = 0; i < submenu.length; i++) {
                    let provider = submenu[i].provider;
                    let foundData = controller.providerData[provider];
                    let disableProvider = submenu[i].isDisableProvider;
                    const isHotProvider = submenu[i].isHotProvider;
                    const isNewProvider = submenu[i].isNewProvider;

                    if (providerMaintenance) {
                        for (let j = 0; j < providerMaintenance.length; j++) {
                            let maintenanceProvider = providerMaintenance[j].provider;
                            if (provider === maintenanceProvider) {
                                submenu[i].gameMaintenance = providerMaintenance[j].gameMaintenance;
                            }
                        }
                    }

                    if (foundData) {
                        Object.assign(foundData, { isDisableProvider: disableProvider });

                        // desktop handling
                        if (!isMobile) {
                            foundData.maintenance = submenu[i].gameMaintenance;
                            let bannerElement = generateCasinoBannerElement(provider, foundData, count, isHotProvider, isNewProvider);
                            count = count + 1;

                            casinoData.push(foundData);
                            list.push(bannerElement);
                        } else {
                            // mobile handling
                            foundData.isHotProvider = isHotProvider;
                            foundData.isNewProvider = isNewProvider;
                            foundData.maintenance = submenu[i].gameMaintenance;
                            list.push(foundData);
                        }

                        providerCodeList.push(provider);
                        controller.vm.setState({ list: list, providerCodeList: providerCodeList });
                        resolve(casinoData);
                    }
                }
            });
        });

        function generateCasinoBannerElement(provider, data, index, isHotProvider, isNewProvider) {
            const updatedData = { ...data };

            if (controller.vm.state.compSettings?.enableCNYTheme === true) {
                // Update img paths to use "_cny.png" suffix
                const updateImagePath = (path) => path?.replace(/(\.png)$/, '_cny$1');
                if (updatedData.suspend) {
                    updatedData.suspend = updatedData.suspend.map((item) => ({
                        ...item,
                        suspendImg: updateImagePath(item.suspendImg),
                    }));
                }

                updatedData.bgImg = updateImagePath(updatedData.bgImg);
                updatedData.girlImg = updateImagePath(updatedData.girlImg);
            }

            const bgImg = updatedData.bgImg || data.bgImg;
            const blockImg = updatedData.blockImg || data.blockImg;
            const girlImg = updatedData.girlImg || data.girlImg;
            const suspendImg = updatedData.suspend || data.suspend;
            return {
                content: (
                    <div>
                        <LazyLoadImage src={bgImg} alt='' className='banner-img' />
                        {blockImg && !controller.vm.state.compSettings?.enableCNYTheme && (
                            <LazyLoadImage
                                key='item-block'
                                style={{ position: 'absolute', left: 0, top: 0, zIndex: 1, pointerEvents: 'none', ...updatedData?.style }}
                                className='item-block'
                                src={blockImg}
                                visibleByDefault={girlImg}
                            />
                        )}
                        {girlImg && (
                            <LazyLoadImage
                                key='item-girl'
                                style={{ position: 'absolute', left: 0, bottom: 0, zIndex: 1, pointerEvents: 'none', ...updatedData?.style }}
                                className='item-girl'
                                src={girlImg}
                                visibleByDefault={blockImg}
                            />
                        )}
                        <div className={'container ' + provider}>
                            {suspendImg.map((item, j) => (
                                <div id={`suspend_${index}_${j}`} className={['suspend'].join(' ')} key={`${index + 1}_${j + 2}`}>
                                    <LazyLoadImage src={item.suspendImg} alt='' />
                                </div>
                            ))}
                            <div className='content'>
                                <span className='icon-box'>
                                    <i className={'icon-' + data.key}></i>
                                </span>
                                <span className='desc' dangerouslySetInnerHTML={{ __html: t(data.text, { interpolation: { escapeValue: false } }) }}></span>
                                <span
                                    className={`btn ${data.maintenance ? 'maintenance-disable' : ''}`}
                                    onClick={() => {
                                        controller.launchGame(provider, data.disableProvider, data.maintenance);
                                    }}
                                >
                                    {t('casino:casino.playNow', 'PLAY NOW')}
                                </span>
                            </div>
                        </div>
                    </div>
                ),
                tab: (
                    <div className={`${data.isDisableProvider ? 'provider-disable' : ''}`}>
                        <i className={['icon-small-' + data.key, 'gray'].join(' ')}></i>
                        <i className={['icon-small-' + data.key, 'on'].join(' ')}></i>
                        {isHotProvider && <div className='hot-provider-casino'></div>}
                        {isNewProvider && <div className='new-provider-casino'></div>}
                    </div>
                ),
                providerMaintenance: data.maintenance ? <Fragment>{data.maintenance && <div className='maintenance-hover-box'></div>}</Fragment> : null,
            };
        }
    },

    launchGame(provider, disableProvider, maintenance) {
        if (!maintenance) {
            if (disableProvider) {
                controller.vm.props.history.push('/redirect-error');
            } else {
                const { language, screen, user } = controller.vm.props;
                let gameObj = {
                    provider: provider,
                    category: 'C',
                };
                let extraParam = null;

                switch (provider) {
                    case 'AB':
                        extraParam = {
                            provider: 'AB',
                            gameHall: 100,
                        };

                        break;
                }
                providerService.launchGame(gameObj, language, user.isLogin, screen, false, extraParam, controller.vm);
            }
        }
    },

    selectProvider(index) {
        const { providerCodeList, isInit } = controller.vm.state;
        // to prevent 1st time loading to execute 2 times
        // control with isInit flag
        if (isInit) {
            controller.vm.setState({ isInit: false });
        } else {
            let providerCode = providerCodeList[index];
            let foundProvider = controller.providerData[providerCode];
            controller.vm.props.history.push('/casino/' + foundProvider.routeName);
        }
    },

    selectProviderBasedOnUrl() {
        const { providerCodeList } = controller.vm.state;
        let switchProvider = true;
        if (window.location.pathname.indexOf('/casino') >= 0 && window.location.pathname.indexOf('/casino/') <= -1) {
            switchProvider = false;
        }

        // check current provider based on url,
        // define selectedIndex to makesure Slider component
        // will auto select the provider when 1st time init
        if (switchProvider && providerCodeList.length > 0) {
            for (let i = 0; i < providerCodeList.length; i++) {
                let providerCode = providerCodeList[i];
                let routeName = controller.providerData[providerCode].routeName;

                if (window.location.pathname.indexOf(routeName) >= 0) {
                    controller.vm.setState({ selectedIndex: i });
                    return i;
                }
            }
            controller.vm.setState({ selectedIndex: 0 });
            return null;
        } else {
            controller.vm.setState({ selectedIndex: 0 });
            return null;
        }
    },
};

export default controller;
