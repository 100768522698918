import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { walletAction } from 'root/redux/action/wallet.action';

import Clock from 'root/webapp/shared/component/Clock';
import Dropdown_Langlist from 'root/webapp/shared/component/Dropdown_Langlist';
import FloatingLogin from 'root/webapp/portal/login/desktop/FloatingLogin';
import FloatingResetPassword from 'root/webapp/portal/reset-password/desktop/FloatingResetPassword';
import SmallLoading from 'root/webapp/shared/loading/smallLoading';
import NewsLetterPopUp from 'root/webapp/portal/news-letter/news-letter-popup';
import SVGElements from 'root/components/SVGElements';
import commonUtil from 'root/utils/common-util';

import vm from '../../navbar.controller';

import leaderboardService from 'root/utils/leaderboard-service';
import LeaderboardRankScore from '../../../../portal/leaderboard/sub-component/shared/leaderboard-rank-score/component/leaderboard-rank-score';
import { gu_filterCommonKey } from '../../../../../utils/general-util';
import HamburgerMenus from '../../../../../components/organisms/hamburger-menus/hamburger-menus';

class Navbar extends React.Component {
    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        const { user } = this.props;
        commonUtil.loadCustomTranslation('navbar', this, function () {});
        commonUtil.getAffiliateLink(this).then(() => {
            vm.getMenu(() => {
                this.processNavMenus();
            });
        });
        commonUtil.loadMemberGroupAutomationSummary(this, user);
        vm.initCompSettings();
        vm.getCurrentPage();
        vm.initAfterLogin();
        vm.getBrandLogo();

        leaderboardService.getMemberRank(this);
        leaderboardService.refreshLeaderboardMonthAndYear(this);

        if (this.props.history.location.pathname === '/login') {
            this.setState({ tag: 'login' });
        } else if (this.props.history.location.pathname === '/resetpassword') {
            this.setState({ tag: 'forgot' });
        } else {
            this.setState({ tag: '' });
        }
    }

    componentWillUnmount() {
        vm.stopMemberMessageUnreadCountTimer();
        this.setState = () => {
            return;
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.portal.pathname !== this.props.portal.pathname) {
            vm.getCurrentPage();
        }

        if (prevProps && this.props.user && this.props.user.account) {
            if (prevProps.user.account !== this.props.user.account) {
                vm.initAfterLogin();
            }
        }

        if (prevProps.wallet.loadWallet !== this.props.wallet.loadWallet) {
            if (this.props.wallet.loadWallet) {
                vm.refreshWallet();
                setTimeout(() => {
                    this.props.dispatch(walletAction.udpateLoadWallet(false));
                }, 500);
            }
        }

        if (this.state.tag !== 'login' && this.props.history.location.pathname === '/login') {
            this.setState({ tag: 'login' });
        } else if (this.state.tag !== 'forgot' && this.props.history.location.pathname === '/resetpassword') {
            this.setState({ tag: 'forgot' });
        } else if (this.state.tag !== '' && ['/home', '/register'].includes(this.props.history.location.pathname)) {
            this.setState({ tag: '' });
        }
    }

    processNavMenus = () => {
        const { firstLayerMenuList } = this.state;
        const { portal } = this.props;
        const customNavMenus = gu_filterCommonKey(portal?.settings?.features?.navbarSettings?.customNavMenus);
        const navMenus = (firstLayerMenuList || []).filter((menu) => (customNavMenus || []).includes(menu.name));
        this.setState({ navMenus: navMenus });
    };

    render() {
        const { currentMemberRank, calendar, memberRanking, secondLayerMenuList, fields, memberGroupImg, memberGroupColor, compSettings, brandLogoObj, navMenus } = this.state;
        const { promotionReducer } = this.props;
        const { promotionData } = promotionReducer;
        const dataPage = this.props?.generalReducer?.dataPage;

        let leaderboardRankScoreProps = {
            currentMemberRank: currentMemberRank,
            calendar: calendar,
            memberRanking: memberRanking,
            fields: fields,
            memberGroupImg: memberGroupImg,
            memberGroupColor: memberGroupColor,

            onMouseAction: vm.onMouseAction,
            onDateYearChange: vm.onDateYearChange,
        };

        let langSource = {
            countryLanguage: this.props.language,
            currentLanguage: this.state.currentLanguage,
            languageList: this.state.languageList,
            getLanguageList: vm.getLanguageList,
            updateCurrentLanguage: vm.updateCurrentLanguage,
            changeLanguage: vm.changeLanguage,
        };

        let loginSource = {
            tag: this.state.tag,
            openResetPasswordPage: vm.openResetPasswordPage,
            goToRegister: vm.goToRegister,
            close: vm.close,
        };

        const pages = [
            { tag: 'login', Component: FloatingLogin },
            { tag: 'forgot', Component: FloatingResetPassword },
        ];

        let userCurrency = this.props.user && this.props.user.account && this.props.user.account.currency ? this.props.user.account.currency : null;

        if (userCurrency === 'KHUSD') userCurrency = 'USD';

        const formatClassName = (menu) => {
            const fRoute = menu?.route?.replace(/^\/(.*)/, '$1');
            const fSref = menu?.sref?.toLowerCase();
            const fDataPage = dataPage?.toLowerCase();
            return [fSref, fRoute].includes(fDataPage) ? 'on' : '';
        };

        return (
            <Translation>
                {(t) => (
                    <div id='header'>
                        <NewsLetterPopUp></NewsLetterPopUp>

                        <div className='top-header'>
                            <div className='container'>
                                <div className='top-header-box'>
                                    <i
                                        data-page={dataPage}
                                        className='icon-logo'
                                        style={{
                                            backgroundImage: `url(${brandLogoObj && brandLogoObj.brandLogo})`,
                                        }}
                                        onClick={() => {
                                            this.props.history.push('/home');
                                        }}
                                    ></i>

                                    <ul className='nav-list'>
                                        {(navMenus || []).map((menu) => (
                                            <li
                                                key={menu.name.toLowerCase()}
                                                data-page={menu.name.toLowerCase()}
                                                className={formatClassName(menu)}
                                                onClick={() => {
                                                    vm.menuClicked(menu);
                                                }}
                                            >
                                                <span>{ t(menu?.translation)||t( menu.content,menu.name)}</span>
                                                {menu.isHotProvider && <LazyLoadImage className='hot-icon' src='/public/html/images/homepage/hot-icon.png' />}
                                                {menu.isNewProvider && <SVGElements className='new-icon' name='new-icon' />}
                                            </li>
                                        ))}

                                        <HamburgerMenus menus={this.state.firstLayerMenuList} handleLogin={vm.openLoginPage} />
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className='second-header'>
                            <div className='container'>
                                <div className='second-header-box'>
                                    <div className='left'>
                                        <Clock {...this.props} />
                                        <Dropdown_Langlist {...langSource} />
                                    </div>

                                    <div className={['right', this.props.user.isLogin ? 'logined' : ''].join(' ')}>
                                        {!this.props.user.isLogin ? (
                                            // before login
                                            <div className='before-login'>
                                                <ul className='down-list'>
                                                    {this.state.secondLayerMenuList.map((menu) => (
                                                        <li key={menu.name} onClick={() => vm.menuClicked(menu)}>
                                                            {t(menu.content, menu.name).toLowerCase()}
                                                        </li>
                                                    ))}
                                                </ul>
                                                <div className='btn-box'>
                                                    <span className='btn btn-login' onClick={() => vm.openLoginPage()}>
                                                        {t('global:global.menu.account.login', 'LOGIN')}
                                                    </span>
                                                    <span
                                                        className='btn btn-sign-up'
                                                        data-page={dataPage}
                                                        onClick={() => {
                                                            this.props.history.push('/register');
                                                        }}
                                                    >
                                                        {t('global:global.menu.account.register', 'JOIN NOW')}
                                                    </span>
                                                </div>
                                            </div>
                                        ) : (
                                            // after login
                                            <div className='after-login'>
                                                {compSettings && compSettings.leaderboardCurrencyShow && <LeaderboardRankScore {...leaderboardRankScoreProps} />}
                                                <div className='account-info-box'>
                                                    <div
                                                        className='currency'
                                                        onClick={() => {
                                                            vm.refreshWallet();
                                                        }}
                                                    >
                                                        {this.state.mainWallet !== null ? (
                                                            <span>
                                                                {userCurrency} {window.SPL_Other.formatAmount(this.state.mainWallet.balance)}
                                                            </span>
                                                        ) : (
                                                            <SmallLoading></SmallLoading>
                                                        )}

                                                        <span className='reload-box' data-page={dataPage}>
                                                            <CSSTransition classNames='rotate360' in={this.state.mainWalletRefreshing} timeout={1000}>
                                                                <i className='icon-refesh'></i>
                                                            </CSSTransition>
                                                        </span>
                                                    </div>

                                                    <div
                                                        className='account-info account-dropdown-menu-div'
                                                        id='dropdownBtn'
                                                        onClick={(e) => {
                                                            commonUtil.dropdownUpdate(e, 'certainElement', this.props, 'accountMenu');
                                                        }}
                                                    >
                                                        {this.props.user.account ? (
                                                            <Fragment>
                                                                <span data-page={dataPage}>{this.props.user.account.login}</span>
                                                                <div className='face'>
                                                                    {this.props.user.account.profilePicture ? (
                                                                        <LazyLoadImage src={this.props.user.account.profilePicture} alt='' />
                                                                    ) : (
                                                                        <LazyLoadImage src='/public/html/images/personal.png' alt='' />
                                                                    )}
                                                                    {this.props.user.totalUnreadMessageCount > 0 && (
                                                                        <span className='count'>{this.props.user.totalUnreadMessageCount}</span>
                                                                    )}
                                                                </div>
                                                            </Fragment>
                                                        ) : (
                                                            <SmallLoading></SmallLoading>
                                                        )}

                                                        <CSSTransition classNames='rotate180' in={this.props.dropdown.certainElementDropdown === 'accountMenu'} timeout={300}>
                                                            <i className='icon-arrow'></i>
                                                        </CSSTransition>

                                                        <CSSTransition
                                                            classNames='spread-bottom'
                                                            in={this.props.dropdown.certainElementDropdown === 'accountMenu'}
                                                            timeout={300}
                                                            unmountOnExit
                                                        >
                                                            <ul className='drop-list'>
                                                                {vm.accountDropDownList.map((menu, i) => (
                                                                    <li
                                                                        key={i}
                                                                        onClick={() => {
                                                                            vm.dropdownMenuClicked(menu.route);
                                                                        }}
                                                                    >
                                                                        <span>{t(menu.content)}</span>
                                                                        {this.props.user.totalUnreadMessageCount > 0 && menu.name === 'message' && (
                                                                            <span>{this.props.user.totalUnreadMessageCount}</span>
                                                                        )}
                                                                        {menu?.name === 'specialForYou' && promotionData?.showPromotionNewTag && (
                                                                            <LazyLoadImage src={`/public/html/images/promotion/navbar-new-tag.svg`} />
                                                                        )}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </CSSTransition>
                                                    </div>
                                                </div>

                                                <div className='down-list'>
                                                    <span
                                                        className='dropdowndot-submenu'
                                                        id='dropdownBtn'
                                                        onClick={(e) => {
                                                            commonUtil.dropdownUpdate(e, 'certainElement', this.props, 'submenu');
                                                        }}
                                                    ></span>
                                                    <CSSTransition
                                                        classNames='spread-bottom'
                                                        in={this.props.dropdown.certainElementDropdown === 'submenu'}
                                                        timeout={300}
                                                        unmountOnExit
                                                    >
                                                        <ul className='dropdowndot-submenu-container'>
                                                            {secondLayerMenuList.map((menu) => (
                                                                <li key={menu.name} onClick={() => vm.menuClicked(menu)}>
                                                                    {t(menu.content, menu.name).toLowerCase()}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </CSSTransition>
                                                </div>

                                                <div className='btn-box'>
                                                    <span
                                                        className='btn btn-deposit'
                                                        data-page={dataPage}
                                                        onClick={() => {
                                                            vm.dropdownMenuClicked('/myaccount/deposit');
                                                        }}
                                                    >
                                                        {t('global:global.breadcums.deposit', 'deposit')}
                                                    </span>
                                                    <span
                                                        className='btn btn-logout'
                                                        onClick={() => {
                                                            vm.logout();
                                                        }}
                                                    >
                                                        {t('global:global.menu.account.logout', 'logout')}
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {pages.map(({ tag, Component }) => (
                            <CSSTransition key={tag} classNames='alert' unmountOnExit timeout={300} in={this.state.tag === tag}>
                                <Component show={true} {...loginSource} />
                            </CSSTransition>
                        ))}
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['home', 'global'])(withRouter(Navbar)));
